import React, { Component, useContext, useEffect, useState } from 'react';
import { HashRouter, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import './scss/style.scss';
import { AuthContext } from './context/Auth'

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const Login = React.lazy(() => import('./views/Login/Login'));

const App = () => {

    const Auth = useContext(AuthContext);

    return (
        <HashRouter>
            <React.Suspense fallback={loading}>
                <Switch>
                    { !Auth.isAuthenticated && <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} /> }
                    { !Auth.isLoading && !Auth.isAuthenticated && <Redirect to="/login" /> }

                    { Auth.isAuthenticated && <Route path="/" name="Home" render={props => <TheLayout {...props} />} /> }



                    {/* <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                    <Route path="/" name="Home" render={props => <TheLayout {...props} />} /> */}

                    {/* { !authState.isAuthenticated &&
                        <>
                            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                            <Redirect to="/login" />
                        </>
                    }
                    { authState.isAuthenticated &&
                        <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
                    } */}
                </Switch>
            </React.Suspense>
        </HashRouter>
    );
}

export default App;
