import React, { useState, createContext, useContext } from 'react'
import {
    CToast,
    CToastBody,
    CToastHeader,
    CToaster,
} from '@coreui/react'

const ToastContext = createContext();

const useToast = () => {

    const toast = useContext(ToastContext);

    return {
        showSuccess: toast.showSuccess,
        showError: toast.showError,
        showInfo: toast.showInfo,
        showWarning: toast.showWarning,
    }
};

const ToastComponent = ({ children }) => {

    const [toasts, setToasts] = useState([]);
    const addToast = (color, titulo, mensaje) => {
        setToasts([
            ...toasts,
            {
                position: 'top-right',
                autohide: 3000,
                closeButton: true,
                fade: true,
                color: color,
                titulo: titulo,
                mensaje: mensaje
            }
        ])
    };

    const toasters = (() => {
        return toasts.reduce((toasters, toast) => {
            toasters[toast.position] = toasters[toast.position] || []
            toasters[toast.position].push(toast)
            return toasters
        }, {})
    })();

    const isText = (nuevoMensaje, mensajeDefault) => {

        if (!nuevoMensaje) {
            return mensajeDefault
        };

        if (typeof nuevoMensaje === 'string') {
            return nuevoMensaje
        }

        if (typeof nuevoMensaje === 'object') {
            if( nuevoMensaje.isAxiosError ){
                if( nuevoMensaje.response && nuevoMensaje.response.data && nuevoMensaje.response.data.mensaje ){
                    return nuevoMensaje.response.data.mensaje;
                }
            }
        }

        return mensajeDefault;
    };

    const showSuccess = (nuevoMensaje) => {
        let mensaje = isText(nuevoMensaje, 'Operación exitosa');
        addToast('success', 'Exito', mensaje);
    };

    const showError = (nuevoMensaje) => {
        console.log('nuevoMensaje: ' + typeof nuevoMensaje);
        let mensaje = isText(nuevoMensaje, 'Error al realizar la operación');
        addToast('danger', 'Error', mensaje);
    };

    const showInfo = (nuevoMensaje) => {
        let mensaje = isText(nuevoMensaje, 'Mensaje informativo');
        addToast('info', 'Información', mensaje);
    };

    const showWarning = (nuevoMensaje) => {
        let mensaje = isText(nuevoMensaje, 'Mensaje de advertencia');
        addToast('warning', 'Advertencia', mensaje);
    };

    return (
        <ToastContext.Provider value={{ showSuccess, showError, showInfo, showWarning }}>
            {Object.keys(toasters).map((toasterKey) => (
                <CToaster
                    position={toasterKey}
                    key={'toaster' + toasterKey}
                >
                    {
                        toasters[toasterKey].map((toast, key) => {
                            return (
                                <CToast
                                    key={'toast' + key}
                                    show={true}
                                    autohide={toast.autohide}
                                    fade={toast.fade}
                                    color={toast.color}
                                >
                                    {/* <CToastHeader closeButton={toast.closeButton}>
                                        {toast.titulo}
                                    </CToastHeader> */}
                                    <CToastBody>
                                        {toast.mensaje}
                                    </CToastBody>
                                </CToast>
                            )
                        })
                    }
                </CToaster>
            ))}

            {children}
        </ToastContext.Provider>
    )
};

export {
    ToastContext,
    useToast,
    ToastComponent,
}
