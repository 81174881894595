import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ToastComponent } from './context/ToastContext';
import store from './store'
import { icons } from './assets/icons'
import Auth from './context/Auth';


React.icons = icons

ReactDOM.render(
    <Provider store={store}>
        <Auth>
            <ToastComponent>
                <App />
            </ToastComponent>
        </Auth>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
