import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

export const AuthContext = React.createContext({})

export default function Auth({ children }) {

    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [userData, setUserdata] = useState({});
    const [token, setToken] = useState('');

    useEffect(() => {
        checkAuth();
    }, []);

    const checkAuth = () => {
        let user = localStorage.getItem('user');
        user = user ? JSON.parse(localStorage.getItem('user')) : null;
        if (user) {
            setUserdata(user);
            setToken(user.token);
            setIsAuthenticated(true);
        }
        setIsLoading(false);
    };

    const login = user => {
        localStorage.setItem('user', JSON.stringify(user))
        setUserdata(user);
        setToken(user.token);
        setIsAuthenticated(true);
    };

    const logout = () => {
        localStorage.clear();
        setUserdata({});
        setToken(null);
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, isLoading, userData, login, logout }}>
            {children}
        </AuthContext.Provider>
    )
}

// Auth.propTypes = {
//     children: PropTypes.oneOfType([
//         PropTypes.func,
//         PropTypes.array
//     ])
// }